import "viewerjs/dist/viewer.css";
import Viewer from "viewerjs";
import { uploadFile } from "@/api/index";
import vuedraggable from "vuedraggable";
import materialCenter from "@/views/my-components/xboot/material-center";
export default {
  name: "uploadPicThumb",
  components: {
    vuedraggable,
    materialCenter
  },
  props: {
    value: {
      type: null
    },
    material: {
      type: Boolean,
      default: false
    },
    preview: {
      type: Boolean,
      default: false
    },
    draggable: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    maxSize: {
      type: Number,
      default: 5
    },
    limit: {
      type: Number,
      default: 5
    },
    width: {
      type: String,
      default: "60px"
    },
    height: {
      type: String,
      default: "60px"
    },
    marginBottom: {
      type: String,
      default: "0"
    },
    accept: {
      type: String,
      default: ".jpg, .jpeg, .png, .gif"
    }
  },
  computed: {
    format() {
      if (this.accept) {
        let format = [];
        this.accept.split(",").forEach(e => {
          format.push(e.replace(".", "").replace(" ", ""));
        });
        return format;
      } else {
        return [];
      }
    }
  },
  data() {
    return {
      accessToken: {},
      uploadFileUrl: uploadFile,
      uploadList: [],
      showMaterialCenter: false
    };
  },
  methods: {
    onEnd() {
      this.returnValue();
    },
    init() {
      this.setData(this.value);
      this.accessToken = {
        accessToken: this.getStore("accessToken")
      };
    },
    handleView(v, i) {
      let image = new Image();
      image.src = v;
      let viewer = new Viewer(image, {
        hidden: function () {
          viewer.destroy();
        }
      });
      viewer.show();
    },
    handleRemove(file) {
      const uploadList = this.uploadList;
      this.uploadList.splice(uploadList.indexOf(file), 1);
      this.returnValue();
    },
    handleSuccess(res, file) {
      if (res.success) {
        file.url = res.result;
        // 单张图片处理
        if (!this.multiple && this.uploadList.length > 0) {
          // 删除第一张
          this.uploadList.splice(0, 1);
        }
        this.uploadList.push(file);
        // 返回组件值
        this.returnValue();
      } else {
        this.$Message.error(res.message);
      }
    },
    handleError(error, file, fileList) {
      this.$Message.error(error.toString());
    },
    handleFormatError(file) {
      this.$Notice.warning({
        title: "不支持的文件格式",
        desc: "所选文件‘ " + file.name + " ’格式不正确, 请选择 " + this.accept + " 图片格式文件"
      });
    },
    handleMaxSize(file) {
      this.$Notice.warning({
        title: "文件大小过大",
        desc: "所选文件‘ " + file.name + " ’大小过大, 不得超过 " + this.maxSize + "M."
      });
    },
    handleBeforeUpload() {
      if (this.multiple && this.uploadList.length >= this.limit) {
        this.$Message.warning("最多只能上传" + this.limit + "张图片");
        return false;
      }
      return true;
    },
    returnValue() {
      if (!this.uploadList || this.uploadList.length < 1) {
        if (!this.multiple) {
          this.$emit("input", "");
          this.$emit("on-change", "");
        } else {
          this.$emit("input", []);
          this.$emit("on-change", []);
        }
        return;
      }
      if (!this.multiple) {
        // 单张
        let v = this.uploadList[0].url;
        this.$emit("input", v);
        this.$emit("on-change", v);
      } else {
        let v = [];
        this.uploadList.forEach(e => {
          v.push(e.url);
        });
        this.$emit("input", v);
        this.$emit("on-change", v);
      }
    },
    setData(v) {
      if (typeof v == "string") {
        // 单张
        if (this.multiple) {
          this.$Message.warning("多张上传仅支持传入数组数据类型");
          return;
        }
        if (!v) {
          return;
        }
        this.uploadList = [];
        let item = {
          url: v,
          status: "finished"
        };
        this.uploadList.push(item);
        this.$emit("on-change", v);
      } else if (typeof v == "object") {
        // 多张
        if (!this.multiple) {
          this.$Message.warning("单张上传仅支持传入字符串数据类型");
          return;
        }
        this.uploadList = [];
        if (v.length > this.limit) {
          for (let i = 0; i < this.limit; i++) {
            this.uploadList.push({
              url: v[i],
              status: "finished"
            });
          }
          this.$emit("on-change", v.slice(0, this.limit));
          this.$emit("input", v.slice(0, this.limit));
          this.$Message.warning("最多只能上传" + this.limit + "张图片");
        } else {
          v.forEach(e => {
            let item = {
              url: e,
              status: "finished"
            };
            this.uploadList.push(item);
          });
          this.$emit("on-change", v);
        }
      }
    },
    selectFile(v) {
      if (!this.multiple) {
        // 单张
        this.uploadList[0] = {
          url: v,
          status: "finished"
        };
      } else {
        v.forEach(e => {
          this.uploadList.push({
            url: e,
            status: "finished"
          });
        });
      }
      this.returnValue();
    }
  },
  watch: {
    value(val) {
      this.setData(val);
    }
  },
  mounted() {
    this.init();
  }
};