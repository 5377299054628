import { icons, iconfont } from "@/libs/icon";
import uploadPicThumb from "@/views/my-components/xboot/upload-pic-thumb";
export default {
  components: {
    uploadPicThumb
  },
  name: "iconChoose",
  props: {
    value: {
      type: String,
      default: ""
    },
    size: String,
    type: String,
    shape: String,
    ghost: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: "输入图标名或选择图标"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    maxlength: Number,
    icon: {
      type: String,
      default: "md-ionic"
    },
    showCustom: {
      type: Boolean,
      default: false
    },
    showInput: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      menu: "1",
      showType: "ionic",
      iconModalVisible: false,
      currentValue: this.value,
      iconData: [],
      key: "",
      tip: "输入英文关键词搜索，比如 success",
      form: {
        url: ""
      },
      ruleValidate: {
        url: [{
          required: true,
          message: "请输入合法的URL链接",
          type: "url",
          trigger: "change"
        }]
      },
      ruleValidate2: {
        url: [{
          required: true,
          message: "请上传图片",
          type: "url",
          trigger: "change"
        }]
      }
    };
  },
  methods: {
    init() {
      this.changeType("ionic");
    },
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.currentValue = this.form.url;
          this.$emit("input", this.currentValue);
          this.$emit("on-change", this.currentValue);
          this.iconModalVisible = false;
        }
      });
    },
    changeType(name) {
      if (this.key) {
        this.handleInput();
      } else {
        let re = [],
          data = [];
        if (name == "ionic") {
          data = icons;
        } else {
          data = iconfont;
        }
        data.forEach(e => {
          e.icons.forEach(item => {
            re.push(item);
          });
        });
        this.iconData = re;
      }
    },
    handleInput() {
      if (this.key) {
        // 搜索
        let re = [],
          data = [];
        if (this.showType == "ionic") {
          data = icons;
        } else {
          data = iconfont;
        }
        data.forEach(e => {
          e.tags.forEach(item => {
            if (item.indexOf(this.key) >= 0) {
              e.icons.forEach(r => {
                re.push(r);
              });
            }
          });
        });
        this.iconData = re;
      } else {
        this.init();
      }
    },
    handleFocus() {
      if (!this.key) {
        this.tip = "";
      }
    },
    handleBlur() {
      if (!this.key) {
        this.tip = "输入英文关键词搜索，比如 success";
      }
    },
    handleChange(v) {
      this.$emit("input", this.currentValue);
      this.$emit("on-change", this.currentValue);
    },
    setCurrentValue(value) {
      if (value === this.currentValue) {
        return;
      }
      this.currentValue = value;
    },
    hanleChoose(v) {
      this.currentValue = v;
      this.$emit("input", this.currentValue);
      this.$emit("on-change", this.currentValue);
      this.iconModalVisible = false;
    }
  },
  watch: {
    value(val) {
      this.setCurrentValue(val);
    }
  },
  mounted() {
    this.init();
  }
};