var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "upload-thumb"
  }, [_c('vuedraggable', {
    staticClass: "list-group",
    attrs: {
      "list": _vm.uploadList,
      "disabled": !_vm.draggable || !_vm.multiple,
      "animation": 200,
      "ghost-class": "thumb-ghost"
    },
    on: {
      "end": _vm.onEnd
    }
  }, _vm._l(_vm.uploadList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "upload-list",
      style: {
        width: `calc(${_vm.width} + 2px)`,
        height: `calc(${_vm.height} + 2px)`,
        lineHeight: _vm.height,
        marginBottom: _vm.marginBottom
      }
    }, [item.status == 'finished' ? _c('div', [_c('img', {
      style: {
        height: _vm.height
      },
      attrs: {
        "src": item.url
      }
    }), _c('div', {
      staticClass: "upload-list-cover"
    }, [_c('Icon', {
      attrs: {
        "type": "ios-eye-outline"
      },
      on: {
        "click": function ($event) {
          return _vm.handleView(item.url, index);
        }
      }
    }), _c('Icon', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: !_vm.preview,
        expression: "!preview"
      }],
      attrs: {
        "type": "ios-trash-outline"
      },
      on: {
        "click": function ($event) {
          return _vm.handleRemove(item);
        }
      }
    })], 1)]) : _c('div', [item.showProgress ? _c('Progress', {
      attrs: {
        "percent": item.percentage,
        "hide-info": ""
      }
    }) : _vm._e()], 1)]);
  }), 0), !_vm.material ? _c('Upload', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.preview,
      expression: "!preview"
    }],
    ref: "upload",
    staticClass: "upload-btn",
    style: {
      width: _vm.width,
      marginBottom: _vm.marginBottom
    },
    attrs: {
      "multiple": _vm.multiple,
      "show-upload-list": false,
      "on-success": _vm.handleSuccess,
      "on-error": _vm.handleError,
      "format": _vm.format,
      "accept": _vm.accept,
      "max-size": _vm.maxSize * 1024,
      "on-format-error": _vm.handleFormatError,
      "on-exceeded-size": _vm.handleMaxSize,
      "before-upload": _vm.handleBeforeUpload,
      "type": "drag",
      "action": _vm.uploadFileUrl,
      "headers": _vm.accessToken
    }
  }, [_c('div', {
    style: {
      width: _vm.width,
      height: _vm.height,
      lineHeight: _vm.height
    }
  }, [_c('Icon', {
    attrs: {
      "type": "md-camera",
      "size": "20"
    }
  })], 1)]) : _vm._e(), _vm.material ? _c('div', {
    staticClass: "ivu-upload-drag",
    style: {
      width: _vm.width,
      height: _vm.height,
      lineHeight: _vm.height
    },
    on: {
      "click": function ($event) {
        _vm.showMaterialCenter = true;
      }
    }
  }, [_c('Icon', {
    attrs: {
      "type": "md-camera",
      "size": "20"
    }
  })], 1) : _vm._e(), _vm.material ? _c('materialCenter', {
    attrs: {
      "acceptImg": _vm.accept,
      "maxSize": _vm.maxSize,
      "multiple": _vm.multiple
    },
    on: {
      "on-change": _vm.selectFile
    },
    model: {
      value: _vm.showMaterialCenter,
      callback: function ($$v) {
        _vm.showMaterialCenter = $$v;
      },
      expression: "showMaterialCenter"
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };